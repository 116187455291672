.check-in-container {
  background-color: #f4f6ff;
  height: 100vh;
  overflow-y: overlay;
  img {
    user-select: none;
  }
  .top-img {
    width: 100%;
    vertical-align: bottom;
  }
  .isCheck-box {
    overflow: hidden;
    .blue-text {
      font-size: 18px;
      font-weight: 700;
      color: #3662ec;
      text-align: center;
    }
    .black-text {
      font-size: 20px;
      font-weight: 400;
      color: #3d3d3d;
      text-align: center;
    }
    &-text--1 {
      margin-top: 38px;
      .blue-text();
    }
    &-text--2 {
      margin-top: 27px;
      .black-text();
    }
    &-code {
      display: block;
      margin: 49px auto 0 auto;
      width: 183px;
      vertical-align: bottom;
    }
    &-text--3 {
      margin-top: 55px;
      .blue-text();
    }
    &-text--4 {
      margin-top: 27px;
      .black-text();
    }
    &-button {
      margin: 27px auto 0;
      display: block;
      width: 196px;
    }
    &-bottom {
      margin: 0 auto;
      display: block;
      position: relative;
      top: -80px;
      right: -140px;
      width: 97px;
    }
  }
  .noCheck-box {
    overflow: hidden;
    .blue-text {
      color: #3662ec;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
    }
    &-text--1 {
      margin-top: 86px;
      .blue-text();
    }
    &-text--2 {
      margin-top: 27px;
      .blue-text();
    }
    &-code {
      display: block;
      margin: 77px auto 0 auto;
      width: 146px;
      vertical-align: bottom;
    }
    &-text--3 {
      margin-top: 19px;
      color: #3d3d3d;
      font-size: 13px;
      font-weight: 700;
      text-align: center;
    }
    &-bottom {
      margin: 0 auto;
      display: block;
      position: relative;
      top: -80px;
      right: -130px;
      width: 97px;
    }
  }
}

@primary-color: #1DA57A;