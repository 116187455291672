.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: overlay;
  background-color: #f5f5f5;
  img {
    user-select: none;
  }
  .chat-list {
    padding: 20px;
    width: 100vw;
    box-sizing: border-box;
    .head-img {
      margin-top: 10px;
      width: 47px;
      border-radius: 10px;
    }
    .type-teacher {
      margin-bottom: 34px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      &-box {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        margin-left: 20px;
        padding: 12px 14px;
        min-width: 20vw;
        max-width: calc(100vw - 174px);
        border-radius: 10px;
        border-top-left-radius: 0px;
        background-color: #e8f1ff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        box-sizing: border-box;
        .box-text {
          font-size: 16px;
          line-height: 24px;
        }
        .box-icons {
          &-item {
            margin-top: 4px;
            margin-left: 17px;
            width: 16px;
            vertical-align: bottom;
          }
        }
      }
      &-time {
        margin-left: 20px;
        width: 47px;
        margin-top: 17.5px;
        color: #a0a4ab;
        font-size: 11px;
      }
    }
    .type-user {
      margin-bottom: 34px;
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: flex-start;
      &-box {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        margin-right: 20px;
        padding: 12px 14px;
        max-width: calc(100vw - 174px);
        min-width: 20vw;
        border-radius: 10px;
        border-top-right-radius: 0px;
        background-color: #0961f5;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        box-sizing: border-box;
        .box-text {
          color: #ffffff;
          font-size: 16px;
          line-height: 24px;
        }
        .box-icons {
          &-item {
            margin-top: 4px;
            margin-left: 17px;
            width: 16px;
          }
        }
      }
      &-time {
        margin-top: 17.5px;
        margin-right: 20px;
        width: 47px;
        color: #a0a4ab;
        font-size: 11px;
        text-align: end;
      }
    }
    .type-audio {
      margin-bottom: 34px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        margin-left: 20px;
        padding: 12px 14px;
        border-radius: 10px;
        border-top-left-radius: 0px;
        background-color: #e8f1ff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        box-sizing: border-box;
        .box-text {
          margin-right: 10px;
          font-size: 16px;
        }
        .box-play {
          width: 16px;
        }
      }
      &-time {
        margin-left: 20px;
        width: 47px;
        color: #a0a4ab;
        font-size: 11px;
      }
    }
    .type-video {
      margin-bottom: 34px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      &-box {
        width: calc(100vw - 174px);
        // height: calc((100vw - 174px) * 9 / 16);
        max-width: 500px;
        flex-shrink: 0;
        margin-left: 20px;
      }
      &-time {
        margin-top: 25px;
        margin-left: 20px;
        width: 47px;
        color: #a0a4ab;
        font-size: 11px;
      }
    }
    .type-image {
      margin-bottom: 34px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      &-box {
        width: calc(100vw - 174px);
        max-width: 500px;
        flex-shrink: 0;
        margin-left: 20px;
      }
      &-time {
        margin-top: 25px;
        margin-left: 20px;
        width: 47px;
        color: #a0a4ab;
        font-size: 11px;
      }
    }
  }
  .loading-text {
    width: 100%;
    height: 40px;
    line-height: 30px;
    color: #999999;
    font-size: 13px;
    text-align: center;
  }
}

@primary-color: #1DA57A;