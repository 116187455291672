.exchange-success-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    user-select: none;
  }
  .icon-box {
    position: relative;
    .icon-img {
      width: 227px;
    }
    .icon-text {
      position: absolute;
      top: 40px;
      right: 10px;
      color: #b35604;
      font-size: 19px;
      font-weight: bold;
    }
  }

  .button-box {
    margin-top: 67px;
    display: flex;
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 149px;
      height: 55px;
      background-color: #eaefff;
      border-radius: 13px;
      color: #1955ff;
      font-size: 16px;
      font-weight: bold;
      &:nth-child(1) {
        margin-right: 27px;
      }
    }
  }
}

@primary-color: #1DA57A;