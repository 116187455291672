.goods-detail-container {
  img {
    user-select: none;
  }
  background-color: #f5f5f5;
  height: 100vh;
  overflow: overlay;
  .goods-pic {
    width: 100%;
  }
  .goods-desc {
    padding: 0px 13px 123px 13px;
    &-block {
      margin-top: 13px;
      padding: 13px;
      border-radius: 13px;
      background-color: #fff;
      .block-text {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }
      .block-integral {
        margin-top: 9px;
        color: #ed1919;
        font-size: 22px;
        font-weight: bold;
      }
      .block-title {
        color: #333333;
        font-size: 15px;
        font-weight: bold;
      }
      .block-details {
        margin-top: 9px;
        color: #333333;
        font-size: 14px;
      }
      .block-content {
        width: 100%;
      }
    }
  }
  .button-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0px 18px 43px;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
  }
  .address-pop {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    &-content {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0;
      padding: 19px 18px 0px 18px;
      width: 100%;
      box-sizing: border-box;
      border-radius: 18px 18px 0px 0px;
      background-color: #fff;
      .content-header {
        position: relative;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-title {
          width: 100%;
          color: #333333;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
        }
        &-icon {
          position: absolute;
          top: calc(50% - 10px);
          right: 0;
          width: 20px;
        }
        &-add {
          display: flex;
          align-items: center;
          .add-icon {
            margin-right: 9px;
            width: 18px;
            height: 18px;
          }
          .add-text {
            color: #1955ff;
            font-size: 15px;
          }
        }
      }
      .content-body {
        flex: 1;
        overflow: overlay;
      }
      .content-address {
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 101px;
        box-sizing: border-box;
        border-radius: 13px;
        border: 1px solid #979797;
        padding: 13px;
        font-size: 15px;
        &-left {
          width: 100%;
          .left-title {
            position: relative;
            display: flex;
            align-items: center;
            &-name {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              max-width: 200px;
              color: #333333;
              font-weight: bold;
            }
            &-phone {
              margin-left: 9px;
              color: #666666;
            }
            &-icon {
              position: absolute;
              right: 0;
              top: 0;
              width: 20px;
            }
          }
          .left-address {
            margin-top: 9px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            color: #333333;
            line-height: 22px;
          }
        }
      }
      .content-goods {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 13px;
        margin-bottom: 13px;
        border-radius: 13px;
        background-color: #f5f5f5;
        &-img {
          width: 92px;
          height: 92px;
          object-fit: contain;
          flex-shrink: 0;
        }
        &-desc {
          flex: 1;
          margin-left: 26px;
          .desc-title {
            color: #333333;
            font-size: 18px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
          }
          .desc-text {
            margin-top: 15px;
            color: #ed1919;
            font-size: 22px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
          }
        }
      }
      .content-select {
        padding: 0 27px;
        background-color: #f5f5f5;
        border-radius: 13px;
        .select-item {
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-left {
            font-size: 16px;
            color: #333333;
          }
          &-right {
            display: flex;
            align-items: center;
            .right-coupon {
              padding: 6px 8px;
              font-size: 12px;
              color: #ffffff;
              background-color: #ed1919;
              border-radius: 5px;
            }
            .right-tip {
              padding: 4px 13px;
              font-size: 12px;
              color: #1955ff;
              border: 1px solid #1955ff;
              border-radius: 5px;
            }
            .right-points {
              color: #ed1919;
              font-size: 12px;
              font-weight: bold;
            }
            .right-icon {
              margin-left: 11px;
              width: 26px;
            }
          }
        }
      }
      .add-item {
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 101px;
        box-sizing: border-box;
        border-radius: 13px;
        border: 1px solid #1955ff;
        &-icon {
          width: 18px;
          height: 18px;
        }
        &-text {
          margin-left: 10px;
          color: #1955ff;
          font-size: 15px;
        }
      }
      .content-footer {
        margin-top: 36px;
        margin-bottom: 43px;
        width: 100%;
        background-color: #fff;
        box-sizing: border-box;
      }
      .content-coupon {
        height: 373px;
        overflow-x: hidden;
        overflow-y: overlay;
        &-item {
          margin-bottom: 13px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 14px;
          border-radius: 13px;
          border: 1px solid #979797;
          .item-left {
            color: #333333;
            font-size: 15px;
            &-title {
              font-weight: bold;
            }
            &-text {
              margin-top: 16px;
            }
          }
          .item-right {
            width: 26px;
          }
        }
        &-item--selected {
          border-color: #1955ff;
        }
      }
    }
  }
}

@primary-color: #1DA57A;