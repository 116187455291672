.video-one-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  .video-box {
    width: 100%;
    height: calc(100vh - 100px);
    height: calc(100vh - 100px - env(safe-area-inset-bottom));
    video {
      width: 100%;
      height: 100%;
      background-color: black;
    }
    .no-video {
      margin-top: 40vh;
      text-align: center;
      color: #333333;
    }
  }
}

@primary-color: #1DA57A;