.home-video-container {
  .student-selected-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: overlay;
    overflow-y: hidden;
    @media screen and (orientation: portrait) and (max-width: 767px) {
      .video-box {
        margin-right: 14px;
        width: 224px;
        flex-shrink: 0;
        .video-item {
          position: relative;
          width: 100%;
          &-cover {
            width: 100%;
            border-radius: 13px;
            object-fit: contain;
          }
          &-play {
            position: absolute;
            width: 28px;
            top: calc(50% - 14px);
            left: calc(50% - 14px);
          }
        }
        .title-item {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #3d3d3d;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          line-height: 27px;
        }
      }
    }

    @media screen and (orientation: portrait) and (min-width: 768px) {
      .video-box {
        margin-right: 14px;
        width: 320px;
        flex-shrink: 0;
        .video-item {
          position: relative;
          flex-shrink: 0;
          width: 100%;
          &-cover {
            width: 100%;
            border-radius: 13px;
            object-fit: contain;
          }
          &-play {
            position: absolute;
            width: 28px;
            top: calc(50% - 14px);
            left: calc(50% - 14px);
          }
        }
        .title-item {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #3d3d3d;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          line-height: 27px;
        }
      }
    }
    @media screen and (orientation: landscape) {
      .video-box {
        margin-right: 14px;
        width: 320px;
        flex-shrink: 0;
        .video-item {
          position: relative;
          flex-shrink: 0;
          width: 100%;
          &-cover {
            width: 100%;
            border-radius: 13px;
            object-fit: contain;
          }
          &-play {
            position: absolute;
            width: 28px;
            top: calc(50% - 14px);
            left: calc(50% - 14px);
          }
        }
        .title-item {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #3d3d3d;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          line-height: 27px;
        }
      }
    }
  }
  .style-display-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: overlay;
    overflow-y: hidden;
    @media screen and (orientation: portrait) and (max-width: 767px) {
      .video-box {
        margin-right: 7px;
        width: 126px;
        flex-shrink: 0;
        .video-item {
          flex-shrink: 0;
          position: relative;
          width: 100%;
          &-cover {
            width: 100%;
            border-radius: 13px;
            object-fit: contain;
          }
          &-play {
            position: absolute;
            width: 28px;
            top: calc(50% - 14px);
            left: calc(50% - 14px);
          }
        }
        .title-item {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #3d3d3d;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          line-height: 27px;
        }
      }
    }

    @media screen and (orientation: portrait) and (min-width: 768px) {
      .video-box {
        margin-right: 10px;
        width: 180px;
        flex-shrink: 0;
        .video-item {
          flex-shrink: 0;
          position: relative;
          width: 100%;
          &-cover {
            width: 100%;
            border-radius: 13px;
            object-fit: contain;
          }
          &-play {
            position: absolute;
            width: 28px;
            top: calc(50% - 14px);
            left: calc(50% - 14px);
          }
        }
        .title-item {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #3d3d3d;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          line-height: 27px;
        }
      }
    }
    @media screen and (orientation: landscape) {
      .video-box {
        margin-right: 10px;
        width: 180px;
        flex-shrink: 0;
        .video-item {
          position: relative;
          flex-shrink: 0;
          width: 100%;
          &-cover {
            width: 100%;
            border-radius: 13px;
            object-fit: contain;
          }
          &-play {
            position: absolute;
            width: 28px;
            top: calc(50% - 14px);
            left: calc(50% - 14px);
          }
        }
        .title-item {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #3d3d3d;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          line-height: 27px;
        }
      }
    }
  }
}
.display-video-container {
  width: 100%;
  height: 100%;
  background-color: black;
  .video-pop-top {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    height: 100px;
    &-close {
      margin-right: 10px;
      width: 40px;
    }
  }
  .video-pop-box {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    video {
      width: 100%;
      height: calc(100% - 20px);
    }
  }
}

@primary-color: #1DA57A;