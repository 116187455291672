@w: 154px;
.collectBox {
  // margin-bottom: calc(80px + 20px);
  // margin-bottom: calc(80px + env(safe-area-inset-bottom) + 20px);
  .collect-list {
    overflow-x: overlay;
    overflow-y: hidden;
    display: flex;
    @media screen and (orientation: portrait) and (max-width: 767px) {
      &-item {
        margin-right: 7px;
        width: calc((100% - 14px) / 3);
      }
    }
    @media screen and (orientation: portrait) and (min-width: 768px) {
      &-item {
        margin-right: 10px;
        width: 180px;
      }
    }
    @media screen and (orientation: landscape) {
      &-item {
        margin-right: 10px;
        width: 180px;
      }
    }
    &-item {
      flex-shrink: 0;
      .item-img {
        height: auto;
        img {
          margin: 0 auto;
          width: 100%;
          max-width: 200px;
          height: auto;
        }
      }
      .rv-image__img--normal {
        img {
          aspect-ratio: 0.75;
        }
      }
      .item-text {
        margin-top: 3px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        color: rgb(56, 56, 56);
        font-size: 14px;
        text-align: center;
      }
    }
  }
  .rv-image__img {
    margin: 0 auto;
    width: auto;
    height: 168px;
    border-radius: 13px;
  }
}

@primary-color: #1DA57A;