.pro-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  overflow: hidden;
  .round {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: conic-gradient(#e8e8e8 0% 100%, #1955ff 100% 100%);
    // background-color: #1955ff;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    background-color: #fff;
    font-size: 7px;
    font-weight: bold;
    color: #1955ff;
  }
}

@primary-color: #1DA57A;