.common-video-container {
  img {
    user-select: none;
  }
  position: relative;
  width: 100%;
  height: 100%;
  .video-item {
    width: 100%;
    height: 100%;
  }
  .control-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &-button {
      width: 27px;
    }
    z-index: 1000;
  }
  .no-controls {
    video::-webkit-media-controls {
      display: none !important;
    }

    video::-webkit-media-controls-fullscreen-button {
      display: none !important;
    }

    video::-webkit-media-controls-playback-rate-button {
      display: none !important;
    }

    video::-webkit-media-controls-timeline,
    video::-webkit-media-controls-current-time-display,
    video::-webkit-media-controls-time-remaining-display {
      display: none !important;
    }
    video::-x5-media-controls {
      display: none !important;
    }
  }
}

@primary-color: #1DA57A;