.logoff-container {
  background-color: #fff;
  height: 100vh;
  .risk-img {
    display: block;
    margin: 20px auto 0;
    width: 80px;
    user-select: none;
  }
  .risk-title {
    text-align: center;
  }
  .des-wrapper {
    padding: 30px;
    p {
      font-size: 14px;
    }
    .off-button {
      margin-top: 60px;
      margin-bottom: 20px;
    }
    .off-check {
      font-size: 12px;
      justify-content: center;
    }
  }
  .pop-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    .pop-wrapper {
      width: 305px;
      height: 358px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url("/images/image/lesson-start.png");
      &-title {
        margin-top: 70px;
        margin-left: 50px;
        font-size: 26px;
        font-weight: bold;
        color: rgba(0, 107, 152, 1);
      }
      &-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-left: 30px;
        padding: 0px 6px;
        box-sizing: border-box;
        height: 128px;
        width: 252px;
        color: rgba(0, 107, 152, 1);
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        background-color: rgba(40, 168, 222, 0.1);
        border-radius: 6px;
        &__color1 {
          color: rgba(255, 139, 35, 1);
        }
      }
      &-button {
        margin: 20px auto 0;
        width: 240px;
        display: flex;
        justify-content: space-between;
        .button-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 110px;
          height: 47px;
          background-size: 100%;
          background-repeat: no-repeat;
          color: #fff;
          font-size: 16px;
        }
        .button-cancel {
          background-image: url("/images/image/start-lesson-cancel.png");
        }
        .button-confirm {
          background-image: url("/images/image/start-lesson-confirm.png");
        }
      }
    }
  }
}

@primary-color: #1DA57A;